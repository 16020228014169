import { authHeader } from "./auth-header";

export const userService = {
  login,
  logout,
  getProfile,
  register,
  /*getAll,*/
};

function login(email, password) {
    console.log('email:', email)
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email, password }),
  };

  return fetch(`https://174.138.181.206/agence/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      console.log("user:", user);
      // login successful if there's a jwt token in the response
      if (user.tokenSalt) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //localStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}

function register(
  designation,
  ifu,
  rccm,
  ville,
  indicatifTel,
  tel,
  email,
  password,
  responsable
) {
  console.log("register:");
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      designation,
      ifu,
      rccm,
      ville,
      indicatifTel,
      tel,
      email,
      password,
      responsable,
    }),
  };

  console.log("requestOptions:", requestOptions);

  return fetch(`https://174.138.181.206/agence/register`, requestOptions)
    .then(handleResponse)
    .then((agence) => {
      console.log("handleResponse:", handleResponse);
      console.log("agence:", agence);
      // login successful if there's a jwt token in the response
      /*if (agence.tokenSalt) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('user', JSON.stringify(user));
                sessionStorage.setItem('agence', JSON.stringify(agence));
            }*/
      return agence;
    });
}

function getProfile() {
  //let user = JSON.parse(localStorage.getItem('user'));
  let user = JSON.parse(sessionStorage.getItem("user"));
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `http://216.158.239.208:1337/image/${user.userAvatarCode}`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  sessionStorage.removeItem('user');
}

/*function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`/users`, requestOptions).then(handleResponse);
}*/

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
