import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import MazInput  from 'maz-ui'
import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import i18n from './i18n'

import "@/assets/scss/app.scss";
/*if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}*/
Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(MazInput)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
